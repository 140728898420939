
@media  ( max-width : 990px ){

    .carouselHero{ width:100%; }

    #serviceBarAbove { display: none; }
    #serviceBarBelow { display: block; }
    
    .serviceIcons{ float:none; width:80%; margin:0 auto; }
    
    .serviceIcons div{
        border: 2px solid white;
        float:left;
        width:47%;
        height: auto;
        margin: 4px; 
    }
    .serviceIcons div a{ 
        width:100%; 
        text-align:center; 
        position: relative;
    }
    .serviceIcons div a:hover{ text-decoration:none; }
    .serviceIcons div a img{ padding:0px; width:100%; display:block; }

    .serviceIcons div a:last-of-type{  
        font-size:20px; 
        font-weight:500;
        height: 34px;
    }
    
    .payment{ float:none;width:80%; border:8px solid #eee; }

    .payment a{
        padding-left:35px; 
        color:#000;
        text-decoration:underline; 
        display:inline-block;
        font-size:25px;
        margin:40px auto 10px;
        font-weight:bold;         
        position: relative;    
        &:before{
            content: '';
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            z-index: 1;
            display: inline-block;
            @include sprite($sprite-lockIcon);
        }
    }
    .payment a:hover{ text-decoration:none; color:#060; }
    .payment img{ display:inline-block; width:60%;  display:block; margin:0 auto; width:268px; }
    
    .productSec h2{ text-align:center; }

    
}


@media  ( max-width : 667px ){

    .serviceIcons{ float:none; width:94%; margin:0 auto; }
    .payment{ margin:auto; float:none; width:94%; border:8px solid #eee; }

    .payment a{
        padding-left:35px; 
        color:#000;
        text-decoration:underline; 
        display:inline-block;
        font-size:25px;
        margin:40px auto 10px;
        font-weight:bold;         
        position: relative;    
        &:before{
            content: '';
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            z-index: 1;
            display: inline-block;
            @include sprite($sprite-lockIcon);
        }
    }
    .payment a:hover{ text-decoration:none; color:#060; }
    .payment img{ display:inline-block; width:60%;  display:block; margin:0 auto; width:268px; }
    
    #testim-home {
        padding-left: 0;
        background: none;
    } 
    #testim-home blockquote{  
        padding-left: 55px;
        border-left-width: 0;
        position: relative;   
        &:before{
            content: '';
            position: absolute;
            left: 5px;
            display: inline-block;
            @include sprite($sprite-quote);
        }
        &:after{
            content: '';
            position: absolute;
            top: 0;
            left: 42px;
            bottom: 0;
            display: block;
            height: 100%;
            width: 5px;
            background-color: #eee;
        }
    }
    .swiper-testimonials {
        .swiper-button-next,
        .swiper-container-rtl .swiper-button-prev,
        .swiper-button-prev, 
        .swiper-container-rtl .swiper-button-next{
            display: block;
            background-image: none!important;
            width: 20px!important;
            height: 37px!important;
            background-size: 20px 37px!important;
        }
        .swiper-button-next,
        .swiper-button-prev, 
        .swiper-container-rtl .swiper-button-prev,
        .swiper-container-rtl .swiper-button-next{
            fill: #0c9126;
        }
    } 
    
}
@media ( max-width : 500px ){
    .split-two{
        width: 100%;
        float:none;
    }
}
@media ( max-width : 430px ){
    .mobile-banner-link{
        margin: 0 -15px;
        position: relative;
        display: block; 
    }
    #mobile-banner { display: block; width: 100% }
    #customerReviewBanner  { display: none; }
    .serviceIcons{ float:none; width:100%; margin:0 auto; }

    .payment{ float:none; width:98%; border:8px solid #eee; }

    .serviceIcons div a:last-of-type{  
        font-size:20px;
        font-weight:500;
        height: 54px;
    }
    .serviceIcons div a span{        
        padding:5px;
    }

    .payment a{
        padding-left:20px;
        color:#000;
        text-decoration:underline;
        display:inline-block;
        font-size:13px;
        margin:40px auto 10px;
        font-weight:bold;         
        position: relative;    
        &:before{
            content: '';
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            z-index: 1;
            display: inline-block;
            @include sprite($sprite-lockIconSml);
        }
    }
    .payment a:hover{ text-decoration:none; color:#060; }
    .payment img{ display:inline-block; width:60%;  display:block; margin:0 auto; width:200px; }
    
}
@media  ( max-width : 375px ){
    .payment a{
        padding-left:20px; 
        color:#000;
        text-decoration:underline; 
        display:inline-block;
        font-size:13px;
        margin:40px auto 10px;
        font-weight:bold; 
        position: relative;    
        &:before{
            content: '';
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            z-index: 1;
            display: inline-block;
            @include sprite($sprite-lockIconSml);
        }
    }
    .payment a:hover{ text-decoration:none; color:#060; }
    .payment img{ display:inline-block; width:60%;  display:block; margin:0 auto; width:200px; }
}
