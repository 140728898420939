.couponCodes{
    .productSec{ 
        width: 100%; 
    }
    .light-content-box{
        border: 6px solid #f9b119!important;
        background:#fff1d2!important;
        p{
            &:last-of-type{
                font-size:20px; 
                strong{
                    color:#f00;
                }
            }
        }
    }
    @media only screen and ( min-width : 431px ){ 
        .hmprod{ 
            width: 180px; 
        }
    }
}