.acc-help {
    padding: 0 3px 11px 0;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: .7em;
    font-weight: 700;
    color: #444;
}

footer {
    margin: 20px auto 0;
    padding-bottom: 10px;
    width: 100%;
    box-shadow: 0px -1px 2px 2px rgba(50, 50, 50, 0.5);

    background: #f9d2f1; /* Old browsers */
    background: -moz-linear-gradient(top, #f9d2f1 0%, #d9a4ce 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f9d2f1), color-stop(100%, #d9a4ce)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #f9d2f1 0%, #d9a4ce 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #f9d2f1 0%, #d9a4ce 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #f9d2f1 0%, #d9a4ce 100%); /* IE10+ */
    background: linear-gradient(to bottom, #f9d2f1 0%, #d9a4ce 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9d2f1', endColorstr='#d9a4ce', GradientType=0); /* IE6-9 */

    a{
        &:hover, &:focus{
            color: $brown;
        }
    }
    ul {
        padding: 0;
    }
    h3{ 
        margin: 20px auto;
        font-size: 20px;
        color: #555;
    }
}
#seals{
    a{
        text-decoration: none!important;
    }

}
.footerLinks{ margin:0 auto; width:1000px; overflow:auto; }
.footLinkBox{ margin: 0 auto; float: left; padding: 5px; }
.footLinkBox4Col{ width: 25%; min-width: 210px; }
.footLinkBox2Col{ width: 50%; display: flex; justify-content: center; min-width: 250px; }
.footFirstLink{ width: 30%; }
.footSecondLink{ width: 49%; }
.footThirdLink{    width: 20%;    margin-top: 20px; text-align: center; }

.footLinkBox a{ 
    color:#333;
    font-size:12px; 
    padding: 2px 0;
    display: inline-block;
    width: auto;
    margin: 0;
}
.footLinkBox a:hover{ text-decoration: none; }
.footLinkBoxInner{ float: left; width: 50%; }
.footThirdLink a{ margin-bottom: 10px; display: inline-block; }

.socialIcons a{ 
    padding:0 2px; 
    text-decoration:none;
    color: #fff!important;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    font-size: 16px!important;
    position: relative;
    display: inline-block;
    &:hover{
      color: #fff!important;
    }
}
.socialIcons .fa{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%,-50%);
}
.s-rss{
    background-color: #f57d00;
}
.s-facebook{
    background-color: #3b5999;    
}
.s-instagram{
    background-color: #e4405f;
}
.s-twitter{
    background-color: #55acee;
}
.s-linkedin{
    background-color: #0077B5;
}
.s-pinterest{
    background-color: #bd081c;
}

.footerContent{ margin: 0 auto; width: 1000px; border-top: 1px solid #ec92da; }
.footerContent p.pin{ font-family: Arial, Helvetica, sans-serif; font-size: 15px; color: #333; text-align: center; }
.footerContent p{ text-align: center; font-family: Arial, Helvetica, sans-serif; font-size: 13px; }
