/**
* SECONDARY CATEGORY STYLES
*/

.cd-main-container {
	div {
		margin: 0;
		padding: 0;
		margin: 0;
		padding: 0;
		box-sizing: inherit;
		&::before {
			margin: 0;
			padding: 0;
			box-sizing: inherit;
		}
		&::after {
			margin: 0;
			padding: 0;
			box-sizing: inherit;
		}
	}
	margin: 0;
	padding: 0;
	box-sizing: inherit;
	background: $white;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	display: flex;
	padding-left: 0px !important;
	padding-right: 0px !important;
}
.secondary-category-show {
	display: flex;
	gap: 8px;
}
.secondary-card {
	height: 150px;
	background-color: rgba(0, 0, 0, 0.4);
	color: $white;
	font-size: 40px;
	margin: 5px;
	flex: 155px 0 0;
	border-radius: 10%;
	text-align: start;
	overflow: hidden;
	.show-now {
		width: 50%;
		z-index: 99;
		font-size: 10px;
		margin-left: 15px;
		white-space: nowrap !important;
		font-weight: 800;
		bottom: 10px;
		position: absolute;
	}
	h3 {
		font-weight: bold;
		z-index: 99;
		margin-left: 15px;
		position: absolute;
		bottom: 40px;
		font-size: 20px !important;
		color: $white;
	}
	img {
		border-radius: 10%;
		max-width: 100%;
	}
}
.image-container {
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
		border-radius: 10%;
	}
}
.secondary-cat-img {
	display: block;
	width: 100%;
	height: auto;
}
.cards {
	img {
		object-fit: cover;
	}
}
.cards-wrapper {
	display: flex;
	transition: ease 0.5s;
}
.display-area {
	width: 100%;
	overflow-x: hidden;
	margin: auto;
}
.slider-action-buttons {
	z-index: 50;
	button {
		height: 35px;
		z-index: 150;
		position: absolute;
		top: 35%;
		border: none;
		border-radius: 50%;
		background: none;
		cursor: pointer;
		svg {
			border-radius: 60%;
			background: $white;
		}
	}
	.next {
		right: 0;
	}
}
.homepage-slider {
	a {
		&:visited {
			color: $white;
		}
	}
}
.hidden-element {
	visibility: hidden !important;
}
@media only screen and (min-width: 1360px) {
	.cd-main-container {
		max-width: 1280px;
		margin-left: auto;
		margin-right: auto;
	}
}
@media only screen and (max-width: 1000px) {
	.display-area {
		position: relative;
		overflow-x: clip;
	}
	.secondary-category-show {
		gap: 20px 6px;
		flex-wrap: nowrap;
	}
	.slider-action-buttons {
		width: 100%;
		z-index: 50;
		display: flex;
		justify-content: space-between;
	}
}
@media only screen and (min-width: 1000px) {
	.display-area {
		position: relative;
		overflow-x: clip;
	}
	.cards-wrapper {
		transform: translateX(0px) !important;
	}
	.secondary-category-show {
		width: 100%;
		justify-content: center;
	}
	.slider-action-buttons {
		display: none;
	}
}
@media only screen and (max-width: 350px) {
	.secondary-card {
		height: 105px;
		flex: 105px 0 0;
		a {
			width: 63%;
			font-size: 8px;
			margin-left: 5px;
		}
		h3 {
			margin-left: 5px;
			bottom: 30px;
			font-size: 17px !important;
		}
	}
}