/**
* Search bar and result page For KLevu Smart Search
*/

.kuSearchResultsPageContainer {
	.kuFilterBox {
		.kuFilterNames {
			ul {
				li {
					a {
						span {
							&.kufacet-tex {
								t {
									max-width: 70%;
									width: 65%;
								}
							}
						}
					}
				}
			}
		}
	}
}
.hmprod {
	.product_image_price {
		strong {
			font-size: 13px;
			margin-top: 10px;
		}
		span {
			font-size: 13px;
			margin-top: 10px;
		}
	}
}
.kuContainer {
	padding-top: 0 !important;
}
