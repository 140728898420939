
.highCallVolume{
    color: $text-color;
    font-size: 13px;
    max-width: 300px;
    margin: 0 auto 10px;
    clear: both;
    text-align: center;
    img{
        max-width: 80px;
        height: auto;
        float: left;
    }
    span{
        padding-top: 12px;
        padding-left: 15px;
        display: inline-block;
    }
    &.highCallVolumeTop{
        display: block;
        overflow: auto;
        &.csPage{
            font-size: 12px;
            img{
                max-width: 80px;
            }
        }
    }
}