.big-reviews-headline {
    border-bottom: 0px;
    font-size: 32px;
    text-align: left;
    font-family: $font-family-serif;
    color: $brown;
    font-weight: 400;
    margin-bottom: 10px;
    background: 0 0;
}

.website-SA-customer-header {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 30px 0 0;
}

.website-SA-customer-rating {

    .website-SA-customer-name {
      margin: 0;
      padding: 0;
      text-align: left;
      font: normal normal bold 16px/20px Roboto;
      letter-spacing: 0px;
      color: $black;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    text-align: left;
    font: normal normal normal 14px/40px Roboto;
    letter-spacing: 0px;
    color: $black;
    width: 100%;
    .website-SA-rating {
      vertical-align: middle;
      display: inline-block;
      line-height: normal;
      margin-right: 15px;
    }
}

.website-SA-verified-customer {
    text-align: right;
    font: normal normal normal 14px/13px Roboto;
    letter-spacing: 0px;
    color: $black;
    img {
      vertical-align: sub;
    }
}

.website-SA-customer-content {
    position: relative;
    padding-top: 7px;
    p {
      padding: 0;
      margin: 0 0 13px;
      font: normal normal normal 16px/21px Roboto;
      letter-spacing: 0px;
      color: $black;
    }
}

.website-SA-customer-reviews {
    border-bottom: 1px solid $review-border;
    position: relative;
    padding: 0;
}

@media only screen and (max-width: 767px) {
    .website-SA-review-date {
      float: right;
      line-height: normal;
      text-align: right;
      font-size: 12px;
      padding: 0;
      width: 100%;
    }
}

@media only screen and (max-width: 479px) {
    .website-SA-customer-rating {
      .website-SA-rating {
        margin-right: 0px;
      }
    }
}

.reviews-btn-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    #big-reviews-headline {
      border-bottom: 0px;
      font-size: 32px;
      text-align: left;
      font-family: $font-family-serif;
      color: $brown;
      font-weight: 400;
      margin-bottom: 10px;
      background: 0 0;
    }
    #avasReviewForm {
      border-radius: 8px;
    }
}

.review-btn-outer-div {
    border-bottom: 1px solid $gray-lighter;
    .alert {
      margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
    .reviews-btn-div {
      align-items: flex-start;
      #writeReview {
        width: 100%;
      }
    }
}

@media (max-width: 999px) {
    .reviews-btn-div {
      flex-direction: column;
    }
    #writeReview {
      margin-bottom: 10px;
    }
}
