.iconFooter{    
    display: inline-block;
    vertical-align: middle;
}
.iconFooterAddress1 {
    @include sprite($sprite-footerAddress1);
}
.iconFooterAddress2 {
    @include sprite($sprite-footerAddress2);
}
.iconFooterAddress3 {
    @include sprite($sprite-footerAddress3);
}