body:not(.addonPage){
    .validation:not(:empty){
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .validation p{
        color: #404040;
        background-color: #ffe0e0;
        font-weight: 400;
        padding: 10px 10px 10px 35px!important;
        border-top: #a00 solid 1px;
        border-bottom: #a00 solid 1px;
        position: relative;    
        &:before{
            content: '';
            position: absolute;
            top:15px;
            left: 10px;
            z-index: 1;
            display: inline-block;
            @include sprite($sprite-delete);
        }
    }
}
.success-box-big {
    background-color: #e3ffdf;
    padding: 15px;
    padding-left: 60px;
    font-size: 1.2em;
    color: #1c7300;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #1c7300;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    position: relative;   
    &:before{
        content: '';
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        display: inline-block;
        @include sprite($sprite-boxSuccessBig);
    }
}
.success-box-medium {
    background: #e3ffdf;
    padding: 12px;
    padding-left: 60px;
    font-size: 1.0em;
    color: #1c7300;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #1c7300;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        display: inline-block;
        @include sprite($sprite-boxSuccessBig);
    }
}

.error-box-medium {
    background: #ffe6d8;
    padding: 12px;
    padding-left: 60px;
    font-size: 1.0em;
    color: #ce381e;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #ce381e;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        display: inline-block;
        @include sprite($sprite-boxErrorBig);
    }
}
.info-box-medium {
    background: #ecf9ff;
    padding: 12px;
    padding-left: 60px;
    font-size: 1.0em;
    color: #333;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #3399ff;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        display: inline-block;
        @include sprite($sprite-iconInfoLarge);
    }
}
.info-box-medium.info-white{
    background-color: #fff!important;
    background-position: left 10px center;
    font-weight: bold;    
    border-width: 0px;
}
.warning-box-medium {
    background: #FFEAD1;
    padding: 12px;
    padding-left: 60px;
    font-size: 1.0em;
    color: #333;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #FF8800;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        display: inline-block;
        @include sprite($sprite-orderStatusWarning);
    }
}
.invalid-message,
.invalid-message2 {
    padding: 3px;
    font-size: 1.0em;
    color: #a00;
    margin-top: 4px;
    font-weight: bold;
    padding-left: 12px;
    margin-bottom: 0;
    padding-bottom: 0
}

.success-box-large {
    background: #e3ffdf;
    padding: 12px;
    padding-left: 65px;
    font-size: 1.2em;
    color: #1c7300;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #1c7300;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    background-position: 5px center;
    background-repeat: no-repeat
}
.error-box-large {
    background: #ffe6d8;
    padding: 12px;
    padding-left: 65px;
    font-size: 1.2em;
    color: #ce381e;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #ce381e;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    background-position: 5px center;
    background-repeat: no-repeat
}
.info-box-large {
    background: #ecf9ff;
    padding: 12px;
    padding-left: 65px;
    font-size: 1.2em;
    color: #333;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #3399ff;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    background-position: 5px center;
    background-repeat: no-repeat
}