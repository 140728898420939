/**
* SYMPATHY
*/
#sympathy-items {
    border-top: 5px solid #eee;
    width: 186px
}

.sympathy-item {
    margin: 0px;
    border: 5px solid #eee;
    background: #fff;
    padding: 5px;
    text-align: center;
    border-top: 0;
    display: none;
    cursor: pointer
}
.sympathy-item:hover {
    background: #eee
}
.sympathy-item.active {
    display: block;
}

/**
* COLLECTION
*/
.collection-link {
    margin-top: 10px;
    margin-bottom: 5px;
    display: block;
}
.collection-head-link {
    font-size: 20px;
    text-align: left;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-weight: normal;
    margin-bottom: 10px;
    width: 976px;
}
.was-price {
    color: #f00;
    text-decoration: line-through;
    font-weight: bold
}