// iOS hack
input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="number"],
input[type="search"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}
.form-btn{
    padding: 9px 12px;
    background-color: $white;
    &:hover, &:focus{
        background-color: $gray-light;
    }
}
.form-btn,
.form-control:not(.bootstrap-select){
    width: 100%;
    color: $text-color;
    border: 0; 
    box-shadow: $box-shadow-form;
    font-weight: 600;
    &:focus{
        box-shadow: $box-shadow-form-focus;
    }
}
.input-group-addon{
    border-color: $gray-light;
    box-shadow: $box-shadow-form;
}
.input-group-btn{
    .btn{
        height: 35px;
        box-shadow: $box-shadow-form; 
        &.btn-default {
            border-color: $gray-light;
            background-color: $gray-light;
            &:hover,&:focus {
                border-color: darken($gray-light, 5%);
                background-color: darken($gray-light, 5%);
            }
        }
    }   
}
.has-success{
    .form-control{
        box-shadow: $box-shadow-form-valid;   
    }  
}
.has-error{
    .form-control{
        box-shadow: $box-shadow-form-invalid;   
    }  
}
.has-warning{
    .form-control{
        box-shadow: $box-shadow-form-warning;   
    }  
}
.help-block{
    padding-left: 8px;
}
.help-message{
    font-weight: bold;
}
.form-required{
    position: relative;
    &:after{
        font-family: 'FontAwesome';
        font-weight: normal;
        font-size: 10px;
        content: "\f069";
        position: absolute;
        top: 2px;
        right: 5px;
        color: $red;
        z-index:1;
    }
}
label{
    font-size: 13px;
    font-weight: 400;
    small{
        font-weight: 400;
    }
    &.form-required{
        &:after{
            top: -3px;
            right: -15px;
        }
    }
    @media (min-width: $screen-sm-min) {
        font-size: 14px;
    }
}

.has-success{
    &.form-required{
        &:after{
            content: "\f058";
            color: $green;
        }
    }
    label{
        .form-required{
            &:after{
                content: "\f058";
                color: $green;
            }
        }
    }
}

.has-warning{
    &.form-required{
        &:after{
            content: "\f06a";
            color: $orange;
        }
    }
    label{
        .form-required{
            &:after{
                content: "\f06a";
                color: $orange;
            }
        }
    }
}
.form-count {
    .form-control{
        position: relative;
        padding-right: 30px;
    }
    .charsCounterWrapper{
        position: absolute;
        top: 0;
        right: 0;
        width: 21px;
        height: 42px;
        z-index: 1;
        margin: 0;
        padding: 0;
        background-color: $gray-light;
        border-left: 1px solid #ccc;
        border-top-right-radius: $border-radius-base;
        border-bottom-right-radius: $border-radius-base;
        user-select: none;
        .charsCounter{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }
    }
    &.form-required{
        .charsCounterWrapper{
            .charsCounter{
                top: auto;
                bottom: 9px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

}
@media (min-width: $screen-sm-min) {
    .popover{
        min-width: 320px!important;
    }
}
@media (max-width: $screen-xs-max) {
    .checkbox-inline, .radio-inline{
        display: block;
        margin-left: 0px!important;
    }    
}

.bootstrap-select{
    .dropdown-menu{
        > li{
            > a{
                color: $text-color;
                font-weight: 600;
                small{
                    display: block;
                    padding-left: 0;
                }
                .text-muted{
                    color: $text-color;
                }
            }
            &.active{
                > a,
                .text-muted{
                    color: $text-color!important;
                }
            }
            &.disabled{
                > a,
                .text-muted{
                    color: #777!important;
                }
            }
        }
    }
    .filter-option-inner-inner{
        color: $text-color;
        .text-muted{
            padding-left: 5px;
            color: $text-color;
        }
    }
    .dropdown-toggle{
        .bs-caret{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 21px;
            z-index: 1;
            margin: 0;
            padding: 0;
            background-color: #fafafa;
            border-left: 1px solid #ccc;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        .caret {
            border-width: 6px 6px 0 6px;
            right: 3px !important;
        }
    }
    &.dropup{
        .caret{
            border-width: 0px 6px 6px 6px!important;
        }
    }
}
.form-required-select{
    .caret {
        top: 55% !important;
    }
}

.form-check-input{
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0,0,0,.25);
    border-top-color: rgba(0, 0, 0, 0.25);
    border-right-color: rgba(0, 0, 0, 0.25);
    border-bottom-color: rgba(0, 0, 0, 0.25);
    border-left-color: rgba(0, 0, 0, 0.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    float: left;
    margin-left: -1.5em;
    border-radius: .25em;
    background-color: #000;
    border-color: #000;
}
.form-check-input:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}