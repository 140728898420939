// Import shared mixins/functions/etc
@import "shared";
@import "sharedReview";
@import "sharedTabs";
@import "bootstrap/pagination";

/** 
Custom pages 
*/
@import "pages/websiteCouponCodes";
@import "pages/websiteCustomerPrivacy";
@import "pages/websiteCustomerReview";
@import "sharedFormsFloatingLabels";
@import "sharedCard";
@import "sharedSecondaryCategory";

#mobile-banner {display: none; margin: 0 auto;}


/** 
Vendors
*/
@import "sharedSearch";
@import "sharedFontawesome";
@import "sharedFancybox";
@import "swiper";
@import "websiteTestimonials";


@import "sharedResponsive";
@import "responsive/websiteResponsive";

/**
CASE #3567
Circle of Links
*/
$color_3567: #333;

.nav-category.inline {
	display: inline-block;
	vertical-align: top;
	margin-right: 50px;
}
.read-more-target {
	display: inherit;
	opacity: 0;
	max-height: 0;
	font-size: 0;
	transition: .25s ease;
}
.read-more-state {
	display: none;
	&:checked {
		~ {
			.read-more-wrap {
				.read-more-target {
					opacity: 1;
					font-size: inherit;
					max-height: 999em;
				}
			}
		}
	}
}
.read-more-trigger {
	cursor: pointer;
	display: inline-block;
	padding: 0 .5em;
	color: $color_3567;
	font-size: 1.0em;
	line-height: 2;
	font: inherit;
}
p.read-more-wrap {
	margin: 0;
}
.nav-category-inline-parent {
	margin-left: 275px;
}
@media screen and (max-width: 600px) {
	.nav-category-inline-parent {
		margin-left: 85px;
	}
}