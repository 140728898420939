.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    &:hover{
        .card-body{
            color: $link-hover-color;
        }
    }
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width:100%;
        width: auto;
        vertical-align: middle;
        object-fit: contain;
        object-position: center;
    }
}
.card-body {
    // Enable `flex-grow: 1` for decks and groups so that card blocks take up
    // as much space as possible, ensuring footers are aligned to the bottom.
    flex: 1 1 auto;
    padding: 0.25rem;
    opacity: 1;
    @include transition(all .4s ease);
}

.card-img {
    width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
    position: relative;
}
.card-img-big {
    height: 120px;
    img {
        height: 120px;
    }
}
.card-img-small {
    height: 85px;
    img {
        height: 85px;
    }
}
@media (min-width: 431px) {
    .card-img-big {
        height: 150px;
        img {
            height: 150px;
        }
    }
    .card-img-small {
        height: 105px;
        img {
            height: 105px;
        }
    }

}
.card-link {
    position: absolute;
    inset: 0;
    z-index: 1;
}

//
// Card groups
//
.card-group {
    display: flex;
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
        // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
        flex: 1 0 0%;
        margin-bottom: 5px;
        + .card {
            margin-left: 0;
            border-left: 0;
        }
    }
    @media (min-width: $screen-sm) {
        display: none;
    }
}
#categories-bottom {
    font-size: 11px;
    margin-top: 9px;
    font-weight: 600;
}