.light-content-box {
    background: #fafafa;
    padding: 10px;
    padding-top: 5px;
    margin-bottom: 10px
}
.light-content-box .imgbox img {
    border: 1px solid #fff;
}
.round-content-box {
    border: 1px solid #ccc;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 15px;
    background: #fafafa
}
.round-content-box label {
    float: left;
    width: 155px;
    padding-right: 10px;
    font-weight: 700;
    color: #333;
    font-size: 1.0em
}
.round-content-box .text {
    float: left;
    width: 395px;
    padding: 8px;
    padding-right: 10px;
    padding-left: 10px;
    color: #333;
    font-size: 1.0em;
    border: 1px solid #ccc;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background: #eee;
    font-weight: bold;
}
.round-content-box .select {
    float: left;
    padding: 7px;
    padding-right: 10px;
    padding-left: 10px;
    color: #333;
    font-size: 1.0em;
    border: 1px solid #ccc;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background: #eee;
}
.round-content-box .select:focus, .round-content-box .text:focus {
    border: 1px solid #95d0ff;
    background: #f2f9ff;
    -moz-box-shadow: 0 0 5px #aaa;
    -webkit-box-shadow: 0 0 5px #aaa;
    box-shadow: 0 0 5px #aaa;
}
.round-content-box form p {
    clear: both;
    padding-top: 10px
}
.round-content-box .select {
    float: left;
    width: 155px;
    padding-right: 10px;
    font-weight: 700;
    color: #333;
    font-size: 1.0em
}
.round-content-box .submit {
    font-size: 16px;
}
#virtualFloristBox{    
    position: relative;
    #virtualFloristZips{
        padding-right: 100px!important;
        margin-bottom: 65px;
    }
}

#virtualFloristAbsolute{
    position: absolute;
    bottom: 40px;
    right: 15px;
    z-index: 1;
    display: block;
    width: 80px!important;
    height: auto;
}
