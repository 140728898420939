
#special-notice-home,
#special-notice,
#special-notice-city {
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #ff7e00;
    background-color: #c92b00;
    color: #fff;
    padding: 10px;
    margin-bottom: 15px;
    position: relative;
    text-align: center;
}

#special-notice-home a,
#special-notice a,
#special-notice-city a {
    color: #000;
    font-weight: bold;
}

#special-notice-home p,
#special-notice p,
#special-notice-city p {
    margin-bottom: 0;
}